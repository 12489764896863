:root {
  --s-textcolor: #000000;
  --s-linkcolor: #ADC008;
  --s-linkcolor-hover: #000000;
  --s-alttextcolor: #ADC008;
  --s-btn-color: #ADC008;
  --s-btn-color-hover: #FFFFFF;
  --s-btn-bg-hover: #ADC008;
  --s-btn-border: #ADC008;
}
:root {
  --deskWidth: 1156px;
  --deskSpace: 64px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 17px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 8px;
  }
}
:root {
  --spaceUnit: 45px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 32px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 18px;
  }
}
:root {
  --spaceTotal: 60px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 42px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 26px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .button {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.donateButton {
  background-color: #ADC008;
  color: #FFFFFF;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.013em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  transform: rotate(-10deg);
  padding: 13px 13px 30px;
  position: relative;
  font-weight: bold;
}
.donateButton:after {
  content: '';
  position: absolute;
  bottom: 23px;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-heart-white.svg);
}
@media (max-width: 1023px) {
  .donateButton {
    font-size: 15px;
    line-height: 1.13333333;
    padding: 12px 12px 20px;
  }
}
@media (max-width: 767px) {
  .donateButton {
    font-size: 11px;
    line-height: 1.18181818;
    padding: 8px 8px 20px;
  }
  .donateButton:after {
    width: 11px;
    height: 11px;
    bottom: 20px;
  }
}
@font-face {
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 400;
  src: url("/extras/fonts/tt-norms-pro-regular/font.woff2") format("woff2"), url("/extras/fonts/tt-norms-pro-regular/font.woff") format("woff");
}
@font-face {
  font-family: "TT Norms Pro";
  font-style: normal;
  font-weight: 700;
  src: url("/extras/fonts/tt-norms-pro-bold/font.woff2") format("woff2"), url("/extras/fonts/tt-norms-pro-bold/font.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #ADC008;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'TT Norms Pro', sans-serif;
  line-height: 1.25;
}
.unit caption {
  display: none;
}
.flag {
  background: #ADC008;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  position: relative;
}
.unit .part {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  width: 100%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-size: 30px;
  line-height: 1.93333333;
  font-weight: bold;
  --s-linkcolorhover: #ADC008;
  padding: var(--spacePart) 60px var(--spacePart) 0;
}
div.load a.load:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 46px;
  height: 46px;
  border: 2px solid var(--s-alttextcolor);
  border-radius: 100%;
  background-size: 25px 25px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-download-green.svg);
  transition: all 0.4s;
}
div.load a.load:hover:after,
div.load a.load:focus:after {
  background-color: #ADC008;
  background-image: url(/images/icon-download-lightblue.svg);
}
@media (max-width: 1023px) {
  div.load a.load {
    font-size: 20px;
    line-height: 1.15;
    padding-right: 40px;
  }
  div.load a.load:after {
    width: 26px;
    height: 26px;
    background-size: 13px 15px;
  }
}
@media (max-width: 767px) {
  div.load a.load {
    font-size: 16px;
    line-height: 1;
    padding-right: 30px;
  }
  div.load a.load:after {
    width: 18px;
    height: 18px;
    background-size: 9px 10px;
  }
}
div.load + div.load {
  margin-top: calc(var(--spacePart) * -1);
  border-top: 1px solid var(--s-textcolor);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--s-textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '-';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.layout1 #slides img {
  filter: brightness(60%);
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #ADC008;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #ADC008;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'TT Norms Pro', sans-serif;
  line-height: 1.42857143;
  color: var(--s-textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #ADC008;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #ADC008;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'TT Norms Pro', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 85%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .unit.form .name {
    font-size: 15px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .unit.form .name {
    font-size: 12px;
    line-height: 1.25;
  }
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 15%;
    min-height: 40px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--s-textcolor);
  min-height: 40px;
  padding: 5px 10px;
  appearance: none;
  font-size: 20px;
  line-height: 1.5;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
@media (max-width: 1023px) {
  .unit.form input.text,
  .unit.form textarea {
    font-size: 15px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .unit.form input.text,
  .unit.form textarea {
    font-size: 12px;
    line-height: 1.25;
  }
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 85%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 50px;
  font-size: 20px;
  line-height: 1.5;
  padding-top: 4px;
  padding-bottom: 4px;
}
@media (max-width: 1023px) {
  .unit.form div.tick label {
    padding-left: 30px;
    font-size: 15px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .unit.form div.tick label {
    padding-left: 23px;
    font-size: 12px;
    line-height: 1.25;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 20px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
  font-size: 20px;
  line-height: 1.5;
  width: 37px;
  height: 37px;
}
@media (max-width: 1023px) {
  .unit.form div.tick input {
    font-size: 15px;
    line-height: 1;
    width: 22px;
    height: 22px;
  }
}
@media (max-width: 767px) {
  .unit.form div.tick input {
    font-size: 12px;
    line-height: 1.25;
    width: 16px;
    height: 16px;
  }
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 7.5px;
  }
}
input[type='checkbox']:after {
  content: '';
  width: 29px;
  height: 29px;
  border-radius: 0;
  position: relative;
  background-color: #FFFFFF;
  border: 4px solid var(--s-alttextcolor);
  display: inline-block;
  visibility: visible;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  input[type='checkbox']:after {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
}
@media (max-width: 767px) {
  input[type='checkbox']:after {
    width: 14px;
    height: 14px;
    border-width: 1px;
  }
}
input[type='checkbox']:checked:after {
  background-color: var(--s-alttextcolor);
}
input[type='radio']:after {
  content: '';
  width: 29px;
  height: 29px;
  border-radius: 0;
  position: relative;
  background-color: #FFFFFF;
  border: 4px solid var(--s-alttextcolor);
  display: inline-block;
  visibility: visible;
  transition: all 0.4s;
  border-radius: 1000px;
}
@media (max-width: 1023px) {
  input[type='radio']:after {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
}
@media (max-width: 767px) {
  input[type='radio']:after {
    width: 14px;
    height: 14px;
    border-width: 1px;
  }
}
input[type='radio']:checked:after {
  background-color: var(--s-alttextcolor);
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'TT Norms Pro', sans-serif;
  font-size: 20px;
  line-height: 1.25;
  background: #F5F8FA;
  height: 40px;
  border: 1px solid var(--s-textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 85%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: left;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .unit.form .submit {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .unit.form .submit {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 15% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--s-textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .two-step-verification-container a {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.table tr.init {
  border-top: 0;
}
.table tr.exit {
  border-bottom: 0;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 10px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 10px 10px;
  vertical-align: top;
  font-size: 27px;
  line-height: 1.11111111;
  letter-spacing: 0.054em;
  color: var(--s-textcolor);
}
.table td strong {
  font-weight: bold;
  color: var(--s-alttextcolor);
}
@media (max-width: 1023px) {
  .table td {
    font-size: 20px;
    line-height: 1.15;
  }
}
@media (max-width: 767px) {
  .table td {
    font-size: 16px;
    line-height: 1;
  }
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .part--table::-webkit-scrollbar {
    display: none;
  }
  .part--table .table {
    white-space: nowrap;
    width: auto;
    min-width: 100%;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  transition: all 0.4s;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
}
.layout1 #head {
  width: 100%;
}
.layout2 #head {
  width: auto;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'TT Norms Pro', sans-serif;
  font-size: 20px;
  line-height: 1.25;
  color: var(--s-textcolor);
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 15px;
    line-height: 1.2;
  }
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--two {
  background-color: #F5F8FA;
}
.section--area1__bgWhite .section--area1 {
  background-color: #FFFFFF;
}
#view.area1--empty .section--area1,
#view.area2--empty .section--area2,
#view.area3--empty .section--area3,
#view.area4--empty .section--area4,
#view.area5--empty .section--area5,
#view.area6--empty .section--area6 {
  display: none;
}
.section--header {
  background-color: #FFFFFF;
}
.container--head {
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.section--multimood {
  --s-textcolor: #FFFFFF;
}
.section--multimood .mood {
  float: left;
  position: absolute;
  left: 0;
  top: 0;
}
.section--multimood .content {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section--footer {
  background-color: #ADC008;
  --s-alttextcolor: #000000;
}
.section--footer a {
  transition: all 0.4s;
  --s-linkcolor: #000000;
  --s-linkcolorhover: #FFFFFF;
}
.footarea {
  float: left;
  width: 100%;
  display: grid;
  grid-gap: 0 60px;
  align-items: flex-end;
  position: relative;
}
@media (max-width: 1023px) {
  .footarea {
    grid-gap: 0 20px;
  }
}
@media (max-width: 767px) {
  .footarea {
    grid-gap: 0 2px;
  }
}
.footpart--two {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .footpart--two {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.vcard {
  float: left;
}
.toplink {
  position: absolute;
  right: 0;
  top: -60px;
  border-radius: 100%;
  background-color: #F5F8FA;
  border: 2px solid #ADC008;
  cursor: pointer;
  background-size: 29px 19px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-top-green.svg);
  transform-origin: center;
}
.toplink:hover,
.toplink:focus {
  transform: scale(1.1);
}
@media (max-width: 1023px) {
  .toplink {
    background-size: 15px 15px;
    top: -45px;
  }
}
@media (max-width: 767px) {
  .toplink {
    background-size: 11px 11px;
    top: -31px;
  }
}
.footservices {
  float: left;
  display: flex;
  align-items: flex-end;
  gap: 10px 25px;
}
@media (max-width: 767px) {
  .footservices {
    flex-direction: column;
  }
}
a {
  color: var(--s-linkcolor);
  text-decoration: none;
}
a:hover,
a:focus {
  color: var(--s-linkcolorhover);
}
h1 {
  font-size: 55px;
  line-height: 1.18181818;
  color: var(--s-textcolor);
  text-align: center;
  letter-spacing: 0.02em;
}
@media (max-width: 1023px) {
  h1 {
    font-size: 45px;
    line-height: 1.06666667;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 25px;
    line-height: 1.08;
  }
}
h2 {
  font-size: 55px;
  line-height: 1.05454545;
  color: var(--s-alttextcolor);
}
@media (max-width: 1023px) {
  h2 {
    font-size: 30px;
    line-height: 1.06666667;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 16px;
    line-height: 1.0625;
  }
}
h3 {
  font-size: 27px;
  line-height: 1.11111111;
  letter-spacing: 0.054em;
  color: var(--s-alttextcolor);
}
@media (max-width: 1023px) {
  h3 {
    font-size: 20px;
    line-height: 1.15;
  }
}
@media (max-width: 767px) {
  h3 {
    font-size: 16px;
    line-height: 1;
  }
}
h4 {
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  h4 {
    font-size: 15px;
    line-height: 1.2;
  }
}
h5 {
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.025em;
  color: var(--s-textcolor);
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  h5 {
    font-size: 15px;
    line-height: 1.2;
  }
}
.loud {
  font-size: 35px;
  line-height: 1.28571429;
  color: var(--s-textcolor);
}
@media (max-width: 1023px) {
  .loud {
    font-size: 27px;
    line-height: 1.18518519;
  }
}
@media (max-width: 767px) {
  .loud {
    font-size: 16px;
    line-height: 1.125;
  }
}
.norm {
  color: var(--s-textcolor);
}
.norm a {
  --s-linkcolor: #000000;
  text-decoration: underline;
}
.norm a:hover,
.norm a:focus {
  --s-linkcolorhover: #ADC008;
}
.skew {
  font-size: 27px;
  line-height: 1.11111111;
  letter-spacing: 0.054em;
  color: var(--s-alttextcolor);
  font-weight: bold;
}
@media (max-width: 1023px) {
  .skew {
    font-size: 22px;
    line-height: 1.13636364;
  }
}
@media (max-width: 767px) {
  .skew {
    font-size: 14px;
    line-height: 1;
  }
}
.cb-image-caption {
  font-size: 27px;
  line-height: 1.11111111;
  letter-spacing: 0.054em;
  color: var(--s-alttextcolor);
  font-weight: bold;
  text-align: center;
}
@media (max-width: 1023px) {
  .cb-image-caption {
    font-size: 16px;
    line-height: 1.1875;
  }
}
.vcard {
  letter-spacing: 0.054em;
}
.vcard .name {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .vcard .name {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.015em;
  }
}
@media (max-width: 767px) {
  .vcard {
    font-size: 14px;
    line-height: 1.14285714;
  }
  .vcard .name {
    font-size: 13px;
    line-height: 1.15384615;
  }
}
.part--footTitle span {
  font-size: 27px;
  line-height: 0.92592593;
  letter-spacing: 0.054em;
  color: var(--s-textcolor);
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .part--footTitle span {
    font-size: 18px;
    letter-spacing: 0.02em;
  }
}
@media (max-width: 767px) {
  .part--footTitle span {
    font-size: 14px;
  }
}
.part--disclaimer {
  width: auto;
}
.part--disclaimer .open {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .part--disclaimer .open {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.015em;
  }
}
@media (max-width: 767px) {
  .part--disclaimer .open {
    font-size: 13px;
    line-height: 1.15384615;
  }
}
.area--one {
  display: grid;
  grid-gap: 0 60px;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .area--one {
    grid-gap: 0 20px;
  }
}
@media (max-width: 767px) {
  .area--one {
    grid-gap: 0 2px;
  }
}
.area--one .unit .part.link .open {
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.area--one .unit .part.link .open:hover,
.area--one .unit .part.link .open:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .area--one .unit .part.link .open {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .area--one .unit .part.link .open {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.area--one .unitOne .unit__body {
  display: grid;
  grid-gap: 0 60px;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .area--one .unitOne .unit__body {
    grid-gap: 0 20px;
  }
}
@media (max-width: 767px) {
  .area--one .unitOne .unit__body {
    grid-gap: 0 2px;
  }
}
.area--one .unitTwo {
  display: grid;
  grid-gap: 0 60px;
  align-items: flex-start;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  --s-textcolor: #FFFFFF;
  --s-alttextcolor: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-btn-color: #FFFFFF;
  --s-btn-color-hover: #ADC008;
  --s-btn-bg-hover: #FFFFFF;
  --s-btn-border: #FFFFFF;
}
.area--one .unitTwo--imageLeft .part.pict:first-child {
  left: 50px;
}
.area--one .unitTwo--imageLeft .part.text:nth-child(2) {
  left: 200px;
}
@media (max-width: 1023px) {
  .area--one .unitTwo--imageLeft .part.text:nth-child(2) {
    left: 110px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo--imageLeft .part.pict:first-child {
    left: 20px;
  }
  .area--one .unitTwo--imageLeft .part.text:nth-child(2) {
    left: 60px;
  }
}
.area--one .unitTwo--imageRight .part.pict:first-child {
  right: 50px;
}
.area--one .unitTwo--imageRight .part.text:nth-child(2) {
  left: 0;
}
@media (max-width: 767px) {
  .area--one .unitTwo--imageRight .part.pict:first-child {
    right: 20px;
  }
}
.area--one .unitTwo .unit__content {
  background-color: rgba(173, 192, 8, 0.75);
  box-sizing: border-box;
  grid-row: 1;
  position: relative;
}
.area--one .unitTwo .unit__background {
  grid-row: 1;
}
.area--one .unitTwo .part.pict:first-child {
  position: absolute;
  top: -200px;
  margin: 0;
}
@media (max-width: 1023px) {
  .area--one .unitTwo .part.pict:first-child {
    top: -140px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo .part.pict:first-child {
    top: -75px;
  }
}
.area--one .unitTwo .part.text:nth-child(2) {
  position: absolute;
  bottom: calc(100% + var(--spacePart));
  margin: 0;
  --s-textcolor: #000000;
  --s-linkcolor: #ADC008;
  --s-alttextcolor: #ADC008;
}
@media (max-width: 1023px) {
  .area--one .unitTwo {
    grid-gap: 0 20px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo {
    grid-gap: 0 2px;
  }
}
.area--one .unitThree .unit__background {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.4s;
  z-index: 2;
  pointer-events: none;
}
.area--one .unitThree .part.pict:first-child * {
  height: 100%;
}
.area--one .unitThree .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitThree .part.text:nth-child(2) {
  position: absolute;
  top: -13px;
  left: 45px;
  width: auto;
  padding: 10px;
  margin: 0;
  background-color: #ADC008;
  --s-textcolor: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-alttextcolor: #FFFFFF;
  text-transform: uppercase;
  z-index: 3;
}
@media (max-width: 1023px) {
  .area--one .unitThree .part.text:nth-child(2) {
    top: -10px;
    left: 25px;
  }
}
@media (max-width: 767px) {
  .area--one .unitThree .part.text:nth-child(2) {
    top: -5px;
  }
}
.area--one .unitThree:hover .unit__background,
.area--one .unitThree:focus .unit__background {
  opacity: 1;
}
.area--one .unit--fold.unitFour + .unitFour {
  margin-top: calc(var(--spaceUnit) * -1);
}
.area--one .unit--fold.unitFour--bgWhite:before {
  background-color: #FFFFFF;
}
.area--one .unit--fold.unitFour--bgLightblue:before {
  background-color: #F5F8FA;
}
.area--one .unit--fold.unitFour:before {
  content: '';
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (min-width: 1285px) {
  .area--one .unit--fold.unitFour:before {
    left: calc(((100vw - var(--deskWidth)) / 2) * -1);
  }
}
@media (max-width: 1284px) {
  .area--one .unit--fold.unitFour:before {
    left: -64px;
  }
}
@media (max-width: 767px) {
  .area--one .unit--fold.unitFour:before {
    left: -17px;
  }
}
.area--one .unit--fold.unitFour .more {
  display: block !important;
  overflow: hidden;
  transition: all 0.4s;
  box-sizing: border-box;
}
#view .area--one .unit--fold.unitFour .more {
  height: 0;
}
.area--one .unit--fold.unitFour .less .less.part.pict:first-child {
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
}
#view .area--one .unit--fold.unitFour .less .part.text:last-child {
  position: unset;
}
.area--one .unit--fold.unitFour .fold-toggle {
  display: block;
}
.area--one .unit--fold.unitFour .fold-toggle:before {
  content: '';
  display: block;
  border-radius: 100%;
  background-color: #F5F8FA;
  border: 2px solid #ADC008;
  background-size: 24px 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-bottom-green.svg);
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .area--one .unit--fold.unitFour .fold-toggle:before {
    background-size: 15px 15px;
  }
}
@media (max-width: 767px) {
  .area--one .unit--fold.unitFour .fold-toggle:before {
    background-size: 11px 11px;
  }
}
.area--one .unit--fold.unitFour .fold-toggle.fold-toggle--open:before {
  background-image: url(/images/fold-toggle-close.svg);
}
#view .area--one .unit--fold.unitFour .fold-toggle {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  z-index: 2;
}
@media (max-width: 1023px) {
  #view .area--one .unit--fold.unitFour .fold-toggle {
    bottom: -18px;
  }
}
@media (max-width: 767px) {
  #view .area--one .unit--fold.unitFour .fold-toggle {
    bottom: -10px;
  }
}
#view .area--one .unit--fold.unitFour.unit--foldOpen .more {
  height: var(--js-elementHeight);
}
.area--one .unit--fold.unitFour .unit__foot {
  display: none;
}
.area--one .unitFive {
  background-color: #FFFFFF;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
}
.area--one .unitFive .more {
  display: block !important;
  overflow: hidden;
  transition: all 0.4s;
  box-sizing: border-box;
}
#view .area--one .unitFive .more {
  height: 0;
}
.area--one .unitFive .less .part.pict:first-child * {
  height: 100%;
}
.area--one .unitFive .less .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#view .area--one .unitFive .less .part.text:last-child {
  position: unset;
}
.area--one .unitFive .fold-toggle {
  display: block;
}
.area--one .unitFive .fold-toggle:before {
  content: '';
  display: block;
  border-radius: 100%;
  background-color: #F5F8FA;
  border: 2px solid #ADC008;
  background-size: 24px 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-bottom-green.svg);
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .area--one .unitFive .fold-toggle:before {
    background-size: 15px 15px;
  }
}
@media (max-width: 767px) {
  .area--one .unitFive .fold-toggle:before {
    background-size: 11px 11px;
  }
}
.area--one .unitFive .fold-toggle.fold-toggle--open:before {
  background-image: url(/images/fold-toggle-close.svg);
}
#view .area--one .unitFive .fold-toggle {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  z-index: 2;
}
@media (max-width: 1023px) {
  #view .area--one .unitFive .fold-toggle {
    bottom: -18px;
  }
}
@media (max-width: 1023px) {
  #view .area--one .unitFive .fold-toggle {
    bottom: -10px;
  }
}
#view .area--one .unitFive.unit--foldOpen .more {
  height: var(--js-elementHeight);
}
.area--one .unitFive .unit__foot {
  display: none;
}
.area--one .unitSix .part.pict:first-child * {
  height: 100%;
}
.area--one .unitSix .part.pict:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitSix .part.pict:first-child .cb-image-caption {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  background-color: #FFFFFF;
  padding: var(--spacePart);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.4s;
}
#view .area--one .unitSix .part.pict:first-child .cb-image-caption:hover,
#view .area--one .unitSix .part.pict:first-child .cb-image-caption:focus {
  opacity: 1;
}
.area--one .unitSix .part.text:nth-child(2) {
  position: absolute;
  top: -26px;
  left: 45px;
  width: auto;
  background-color: #ADC008;
  text-transform: uppercase;
  --s-textcolor: #FFFFFF;
  --s-linkcolor: #FFFFFF;
  --s-alttextcolor: #FFFFFF;
  z-index: 3;
}
.area--one .unitSix:hover .unit__background,
.area--one .unitSix:focus .unit__background {
  opacity: 1;
}
.partAlign--center {
  text-align: center;
}
.part--donateButton {
  position: absolute;
  bottom: -75px;
  left: calc((100vw - var(--deskWidth)) / 2);
  border-radius: 1000px;
  z-index: 20;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.part--donateButton .open {
  background-color: #ADC008;
  color: #FFFFFF;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.013em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  transform: rotate(-10deg);
  padding: 13px 13px 30px;
  position: relative;
  font-weight: bold;
}
.part--donateButton .open:after {
  content: '';
  position: absolute;
  bottom: 23px;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-heart-white.svg);
}
@media (max-width: 1023px) {
  .part--donateButton .open {
    font-size: 15px;
    line-height: 1.13333333;
    padding: 12px 12px 20px;
  }
}
@media (max-width: 767px) {
  .part--donateButton .open {
    font-size: 11px;
    line-height: 1.18181818;
    padding: 8px 8px 20px;
  }
  .part--donateButton .open:after {
    width: 11px;
    height: 11px;
    bottom: 20px;
  }
}
.part--donateButton:hover,
.part--donateButton:focus {
  transform: scale(1.1);
}
@media (max-width: 1284px) {
  .part--donateButton {
    left: 64px;
  }
}
@media (max-width: 1023px) {
  .part--donateButton {
    bottom: -43px;
  }
}
@media (max-width: 767px) {
  .part--donateButton {
    left: 17px;
    bottom: -27px;
  }
}
.part--socialLinks {
  width: auto;
}
.part--socialLinks .iconLinks__content {
  float: left;
  width: 100%;
  display: flex;
  gap: 10px;
}
.part--socialLinks .iconLinks__icon {
  float: left;
  width: 30px;
  height: 30px;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  background-color: #000000;
  transition: all 0.4s;
}
@media (max-width: 767px) {
  .part--socialLinks .iconLinks__icon {
    width: 18px;
    height: 18px;
  }
}
.part--socialLinks .iconLinks__icon--instagram {
  mask-image: url(/images/icon-instagram.svg);
}
.part--socialLinks .iconLinks__icon--facebook {
  mask-image: url(/images/icon-facebook.svg);
}
.part--socialLinks .iconLinks__icon:hover,
.part--socialLinks .iconLinks__icon:focus {
  background-color: #FFFFFF;
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .section {
    transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  body.cb-toggle-target-active .section {
    transform: translateY(200px);
  }
  body.cb-toggle-target-active .section.section--header {
    transform: none;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 30px;
  right: 64px;
  z-index: 2001;
  width: 52px;
  height: 28px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .togglenavigation {
    top: 17px;
    right: 17px;
    width: 24px;
    height: 16px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 2px;
  background-color: #000000;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-toggle-target-active .tline {
  background-color: #FFFFFF;
}
@media (max-width: 767px) {
  .tline {
    width: 24px;
  }
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #ADC008;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  font-size: 45px;
  line-height: 1.11111111;
  letter-spacing: 0.02em;
  color: var(--s-linkcolor);
  --s-linkcolor: #FFFFFF;
}
@media (max-width: 767px) {
  .mobile-navigation div.navi > .item > .menu {
    font-size: 25px;
    line-height: 1.12;
  }
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 64px;
  margin: 110px 0 30px;
}
.mobile-navigation div.sub1 > .item {
  margin: 30px 0;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  text-transform: uppercase;
  padding-right: 52px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 {
    padding: 0 17px;
    margin-top: 80px;
  }
  .mobile-navigation div.sub1 > .item {
    margin: 14px 0;
  }
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-size: 40px 25px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-bottom-white.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
@media (max-width: 767px) {
  .mobile-navigation .cb-toggle {
    width: 28px;
    height: 28px;
    background-size: 20px 20px;
  }
}
#home {
  height: 32px;
}
.section--header {
  padding: 10px 0;
}
.container--head {
  width: 100%;
}
.section--multimood {
  height: 217px;
  position: relative;
}
.section--multimood .mood,
.section--multimood .content {
  width: 100%;
  height: 100%;
}
.container--pageTitle {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.section--area1 {
  padding-top: var(--spaceTotal);
}
.section--area6 {
  padding-bottom: var(--spaceTotal);
}
.section--footer {
  padding: 20px 0;
}
.footpart--two {
  margin-top: 15px;
}
.toplink {
  width: 22px;
  height: 22px;
}
.area--one {
  grid-template-columns: 100%;
}
.area--one .unitOne .unit__body {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitOne .partWidth--1-1 {
  grid-column: span 12;
}
.area--one .unitOne .partWidth--1-2 {
  grid-column: span 6;
}
.area--one .unitOne .partWidth--1-4 {
  grid-column: span 6;
}
.area--one .unitTwo {
  grid-template-columns: repeat(12, 1fr);
}
.area--one .unitTwo--imageLeft .unit__content {
  grid-column: 6 / span 7;
  margin-left: -45px;
}
.area--one .unitTwo--imageLeft .unit__background {
  grid-column: 1 / span 5;
  margin-left: -17px;
}
.area--one .unitTwo--imageRight .unit__content {
  grid-column: 1 / span 7;
}
.area--one .unitTwo--imageRight .unit__background {
  grid-column: 8 / span 5;
  margin-right: -17px;
}
.area--one .unitTwo .unit__content {
  width: calc(100% + 45px);
  margin-top: 50px;
  padding: 2px 12px;
}
.area--one .unitTwo .unit__background {
  width: calc(100% + 17px);
}
.area--one .unitTwo .part.pict:first-child {
  width: 40px;
}
.area--one .unitTwo .part.text:nth-child(2) {
  width: calc(100% - 60px);
}
.area--one .unitThree .unit__background {
  height: 150px;
  margin: var(--spacePart) 0;
}
.area--one .unitThree .unit__background .unit__backgroundImage {
  width: auto;
  max-width: calc(100% - var(--spaceUnit)) !important;
  max-height: calc(100% - var(--spaceUnit)) !important;
}
.area--one .unitThree .part.pict:first-child {
  height: 150px;
}
.area--one .unitThree .part.text:nth-child(2) {
  width: auto;
  padding: 6px;
}
.area--one .unitFour {
  padding: 10px 0;
}
#view .area--one .unitFour .less .part.text:last-child {
  margin: 0;
}
.area--one .unitFour .fold-toggle:before {
  width: 22px;
  height: 22px;
}
#view .area--one .unitFour .fold-toggle {
  width: 26px;
  height: 26px;
}
.area--one .unitFive {
  padding: 0 25px 17px 25px;
}
.area--one .unitFive .less .part.pict:first-child {
  margin-top: 0;
  width: calc(100% + 50px);
  margin-left: -25px;
  height: 75px;
}
#view .area--one .unitFive .less .part.text:last-child {
  margin: 0;
}
.area--one .unitFive .fold-toggle:before {
  width: 18px;
  height: 18px;
}
#view .area--one .unitFive .fold-toggle {
  width: 22px;
  height: 22px;
}
.area--one .unitSix .unit__background {
  height: 150px;
}
.area--one .unitSix .part.pict:first-child {
  height: 150px;
}
.area--one .unitSix .part.pict:first-child .cb-image-caption {
  padding: 24px;
}
.area--one .unitSix .part.text:nth-child(2) {
  padding: 6px;
}
.part--donateButton {
  width: 88px;
  height: 88px;
}
.part--footTitle {
  margin-bottom: 10px;
}
.part--pageTitleIcon {
  width: 20px;
  height: 20px;
}
.part--pageTitleIcon .cb-image-figure,
.part--pageTitleIcon .cb-image-container {
  height: 100% !important;
}
.part--pageTitleIcon img {
  width: 100% !important;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  object-position: center;
}
/*# sourceMappingURL=./screen-small.css.map */